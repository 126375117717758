.register-form-cover{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F5F7FB;
}
.register-form-cover>div:first-child{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.register-form-right-side{
    background-color: var(--secondary-color) !important;
}

.register-form{
    width: 950px;
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
    /* height: 100%; */
    margin: 0 auto;
}

.mb-30{
    margin-bottom: 30px;
}

@media only screen and (max-width: 976px) {
    .register-form{
        width: 93%;
    }
}

